<template>
  <a-button type="primary" @click="showDeleteConfirm">删除</a-button>
</template>

<script>
import { deleteAgents } from '@/api/agent'

export default {
  name: 'DestroyAgent',
  props: {
    selectedRowKeys: {
      type: Array,
      required: true
    }
  },
  methods: {
    showDeleteConfirm() {
      const thisDestroy = this
      const selectedRowKeys = thisDestroy.selectedRowKeys
      if (selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择操作对象',
          content: ''
        })
      } else {
        this.$confirm({
          title: '确定删除吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteAgents(selectedRowKeys).then((res) => {
              if (res.code === 0) {
                // 告知父组件已完成
                thisDestroy.$emit('completed')
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
